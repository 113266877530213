<template>
    <div class="index">
        <h1>欢迎使用CRM合同管理系统</h1>
    </div>
</template>
<script>
// import { counter } from '@/utils';
import { mapGetters } from 'vuex';

export default {
    name: 'index',
    computed: {
        ...mapGetters(['modADes', 'modBDes'])
    },
    created() {
        // counter();
    }
};
</script>
